<template>
  <Page class="page-shops page-show-shop" no-gutters>
    <template #title>Dettagli shop</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'shops.edit' }">Modifica</router-link>
      <button v-if="shop.enabled" type="button" @click.prevent="banShop" class="btn btn-outline-danger ml-2">Cancella</button>
    </template>

    <template #default>
      <ul class="nav nav-tabs px-5">
        <li class="nav-item">
          <button class="nav-link" :class="{ active: activeTab === 'data' }" @click.prevent="selectTab('data')">Dati</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" :class="{ active: activeTab === 'offers' }" @click.prevent="selectTab('offers')">Offerte</button>
        </li>
      </ul>
      <div v-if="activeTab === 'data'" class="pt-4 px-4">
        <div class="table-responsive">
          <table class="table table-condensed">
            <tbody>
            <tr>
              <th>Nome</th>
              <td>
                {{ shop.name }}
                <template v-if="shop.enabled">
                  <br><a class="btn btn-outline-secondary" :href="shop.url" target="_blank">Apri</a>
                </template>
              </td>
            </tr>
            <tr>
              <th>Proprietario</th>
              <td>
                <router-link v-if="shop.user" :to="{ name: 'users.show', params: { id: shop.user.id } }">{{ shop.user.firstName }} {{ shop.user.lastName }}</router-link>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th>Lingua</th>
              <td>{{ shop.language }}</td>
            </tr>
            <tr>
              <th>Stato</th>
              <td>{{ shop.enabled ? 'Attivo' : 'Non attivo' }}</td>
            </tr>
            <tr>
              <th>Descrizione</th>
              <td>{{ shop.description }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else-if="activeTab === 'offers'" class="pt-4 px-4">
        <h2 class="mb-0">Offerte attive</h2>
        <div v-if="sellableItems.length > 0" class="row">
          <OfferCard class="col-sm-12 col-lg-6 col-xl my-4" v-for="item in sellableItems" :key="item.id" :offer="item" />
        </div>
        <Alert v-else class="mt-3 mb-5" status="info">Nessuna offerta attiva</Alert>

        <h2 class="mb-0" :class="{ 'mt-4': 0 === sellableItems.length }">Offerte in partenza</h2>
        <div v-if="comingItems.length > 0" class="row">
          <OfferCard class="col-sm-12 col-lg-6 col-xl my-4" v-for="item in comingItems" :key="item.id" :offer="item" />
        </div>
        <Alert v-else class="mt-3 mb-5" status="info">Nessuna offerta in partenza</Alert>

        <h2 class="mb-0" :class="{ 'mt-4': 0 === comingItems.length }">Offerte scadute</h2>
        <div v-if="expiredItems.length > 0" class="row">
          <OfferCard class="col-sm-12 col-lg-6 col-xl my-4" v-for="item in expiredItems" :key="item.id" :offer="item" />
        </div>
        <Alert v-else class="mt-3" status="info">Nessuna offerta scaduta</Alert>
      </div>
    </template>
  </Page>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import Page from '@/views/components/Private/Page.vue';
import OfferCard from '@/views/components/Cards/OfferCard.vue';

export default {
  mixins: [rolesMixin, confirmWithModalMixin],
  components: {
    Page,
    OfferCard,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    activeTab: 'data',
    items: [],
  }),
  computed: {
    sellableItems () {
      return this.items.filter(i => i.isSellable);
    },
    comingItems () {
      return this.items.filter(i => i.isComing);
    },
    expiredItems () {
      return this.items.filter(i => i.isExpired);
    },
  },
  watch: {
    shop: {
      handler (newVal) {
        if (!newVal) {
          return;
        }

        this.$api
          .listItems(null, null, {
            all: true,
            shop: newVal.id,
          })
          .then(res => {
            this.items = res.data.items || [];
          })
          .catch(this.$log.error)
        ;
      },
      immediate: true,
    },
  },
  methods: {
    banShop () {
      if (!this.shop.enabled) {
        return;
      }

      this.confirm('Bannare lo shop?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.banShop(this.shop.id)
            .then(res => {
              this.$emit('update:shop', res.data);
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    selectTab (newTab) {
      if (this.activeTab === newTab) {
        return;
      }

      this.activeTab = newTab;
    },
  },
};

</script>
